<!--  -->
<template>
  <div class="upload-img-box">
    <!-- <el-upload
      :class="{ hide: hideUpload }"
      :action="uploadUrl"
      list-type="picture-card"
      :on-preview="handlePictureCardPreview"
      :on-remove="handleRemove"
      :on-error="handleError"
      :on-change="handleChange"
      :on-success="handleSuccess"
      :headers="{'Authorization':'Bearer ' + token}"
      :limit="limitCount"
      multiple
    >
      <i class="el-icon-plus" />
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img
        width="100%"
        :src="dialogImageUrl"
        alt
      >
    </el-dialog> -->
    <div class="img-box">
      <template v-for="(img, n) in imageList">
        <div :key="n" style="position: relative">
          <img :key="n" :src="img.src" class="show-img" />
          <div :key="n + 'icon'" class="img-in-box">
            <i
              class="el-icon-view img-in-icon"
              @click="
                dialogVisible = true;
                dialogImageUrl = img.src;
              "
            />
            <i
              v-if="!disabled && !img.hideDelete"
              class="el-icon-delete img-in-icon"
              @click="imagehandleRemove(n)"
            />
          </div>
        </div>
      </template>
      <el-upload
        ref="ElUpload"
        :style="{
          height:
            (limitCount ? limitCount : 1) !== imageList.length ? 'auto' : '0',
        }"
        class="uploader"
        :action="uploadUrl"
        :show-file-list="false"
        :on-success="imageSuccess"
        :limit="limitCount || 1"
        :file-list="imageList"
        :disabled="disabled"
        :on-error="imageError"
        :on-progress="imageProgress"
        :before-upload="beforeUpload"
        :headers="{ Authorization: 'Bearer ' + token }"
      >
        <el-progress
          v-if="imgLoadStatus === 'uploading'"
          :type="(listType = 'circle')"
          :width="100"
          style="position: absolute; top: 0; background: #fff"
          :percentage="parsePercentage(percentage)"
        />
        <div v-if="!disabled">
          <i
            v-if="(limitCount ? limitCount : 1) !== imageList.length"
            class="el-icon-plus uploader-icon"
          />
        </div>
      </el-upload>
      <div v-if="disabled && imageList.length === 0">
        <span style="color: rgb(153, 153, 153)">暂无图片</span>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt />
    </el-dialog>
  </div>
</template>

<script>
import setting from "../../../settting";
export default {
  components: {},
  props: {
    limitCount: {
      type: Number,
      default: 1,
    },
    imageList: {
      type: Array,
      default() {
        return [];
      },
    },
    field: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      uploadUrl: "",
      dialogVisible: false,
      dialogImageUrl: "",
      hideUpload: false,
      imgLoadStatus: "",
      token: "",
      imgIndex: 0,
    };
  },
  computed: {},
  created() {
    this.token = sessionStorage.getItem("token");
    this.uploadUrl = setting.uploadUrl;
  },
  mounted() {},
  methods: {
    // 表单上传图片成功时触发
    imageSuccess(res, file, fileList) {
      this.imgLoadStatus = file.status;
      // 此处上传图片判断接口是否成功上传
      if (res.code === 1) {
        this.imgIndex += 1;
        file.src = URL.createObjectURL(file.raw);
        this.$emit("imgUploadSuc", file, fileList, this.field);
        this.$message.success("上传成功");
        // 上传成功时将地址放入form(等待接口完善)
      } else {
        this.imagehandleRemove(this.imgIndex);
        this.imgIndex = this.imgIndex === 0 ? 0 : this.imgIndex - 1;
        this.$message.error(res.msg);
      }
    },
    imageProgress(event, file, fileList) {
      this.percentage = file.percentage;
      this.imgLoadStatus = file.status;
    },
    imagehandleRemove(n) {
      this.$emit("imageRemove", this.field);
      this.imageList.splice(n, 1);
    },
    imageError() {
      this.$message.error("网络错误");
    },
    parsePercentage(val) {
      return parseInt(val, 10);
    },
    beforeUpload(file) {
      // 此处可自定义上传图片的格式大小
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG/png 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 5MB!");
      }
      return isJPG && isLt2M;
    },
  },
};
</script>
<style lang="scss">
.hide .el-upload--picture-card {
  display: none;
}

.uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.uploader .el-upload:hover {
  border-color: #409eff;
}
.uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.show-img {
  width: 100px;
  height: 100px;
  display: block;
}
.img-box {
  display: flex;
  flex-wrap: wrap;
  img {
    margin: 0 10px 10px 0;
  }
}
.img-in-box {
  transition: all 0.2s;
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
  background: rgba(0, 0, 0, 0);
  text-align: center;
  line-height: 100px;
  .img-in-icon {
    opacity: 0;
    padding: 5px;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
  }
}
.img-in-box:hover {
  background: rgba(0, 0, 0, 0.5);
  .img-in-icon {
    opacity: 1;
  }
}
</style>
