import request from '@/until/request.js'
const prefixName = '/gm/webPusLedger'
// 退货单
export function getPurchaseList(data){
  return request({
    url: prefixName,
    method: 'get',
    params: { ...data }
  })
}
// 检查退单号id
export function getPurchaseLedgerDetails(id){
  return request({
    url: prefixName + '/details',
    method: 'get',
    params: { id }
  })
}
// 退货单仓库商品列表
export function editPuchaseLedger(data){
  return request({
    url: prefixName,
    method: 'put',
    data
  })
}
// 创建退货单
export function AddPurchaseRefund(result){
  return request({
    url: prefixName,
    method: 'post',
    data: result
  })
}
// 查看退货单详情
export function GetPurchaseRefundInfo(id){
  return request({
    url: prefixName + '/details',
    method: 'get',
    params: { id:id }
  })
}
// 修改退货单
export function EditPurchaseRefund(result){
  return request({
    url: prefixName,
    method: 'put',
    data: result
  })
}
// 删除
export function deletePurchase(id){
  return request({
    url: prefixName + '/' + id,
    method: 'delete'
})
}
export function checkCode(code,wmsId,productId) {
  return request({
    url:'/gm/wmsPurchaseRefund/checkCode',
    method: 'get',
    params: { code: code,wmsId: wmsId,productId: productId  }
  })
}
// 通过序列码查找批次
export function getBatchByCode(code,productId){
  return request({
    url: '/gm/wmsPurchaseRefund/getBatchByCode',
    method: 'get',
    params: { code:code, productId: productId }
  })
}


export function purchaseLedgerExport(id){
  return request({
    url: prefixName+'/export',
    method: 'get',
    responseType: 'blob',
    params: { id }
  })
}