<template>
  <div>
    <div v-if="!formVisible" class="view-wrap">
      <div class="search">
        <el-form :inline="true">
          <el-form-item>
            <!-- <el-select
              v-model="searcForm.typeId"
              style="width: 100%"
              filterable
              clearable
              placeholder="请选择客户类型"
            >
              <el-option
                v-for="(item, Index) in typeList"
                :key="Index"
                :label="item.name"
                :value="item.id"
              />
            </el-select> -->
          </el-form-item>
          <el-form-item class="hiddenFormItem">
            <el-input
              style="width: 100%"
              v-model="searcForm.param"
              placeholder="请输入编号/供应商姓名/制单人姓名"
            ></el-input>
          </el-form-item>
          <el-form-item label="业务日期">
            <el-date-picker
              v-model="searcForm.date"
              type="daterange"
              range-separator="至"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :clearable="false"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getData"
              ><i class="el-icon-search" /> 查询</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div class="view">
        <SimpleTable
          :data="tableData"
          :tableFields="tableItem"
          :currentPage="searcForm.pageNum"
          :pageSize="searcForm.pageSize"
          :total="total"
          @sizeChange="changePageSize"
          @currentChange="changeCurrentPage"
        >
          <div slot="operation" slot-scope="row">
            <el-button type="text" @click="WatchData(row.data)">查看</el-button>
            <el-button type="text" @click="EditData(row.data)">修改</el-button>
            <el-button type="text" @click="exportData(row.data.id)"
              >导出</el-button
            >
          </div>
        </SimpleTable>
      </div>
    </div>
    <purchaseLedgerForm ref="editForm" v-if="formVisible" @back="backList" />
  </div>
</template>
<script>
import { getPurchaseList, purchaseLedgerExport } from "@/api/purchaseLedger";
import purchaseLedgerForm from "./purchase-ledger-form.vue";
export default {
  components: { purchaseLedgerForm },
  data() {
    return {
      searcForm: {
        pageNum: 1,
        pageSize: 10,
        date: [],
        param: "",
      },
      UpdateType: null,
      tableData: [],
      total: 0,
      tableItem: [
        { label: "操作", type: "operation" },
        { prop: "entryDate", label: "业务日期", type: "default" },
        { prop: "no", label: "台账编号", type: "default" },
        { prop: "purchaseNo", label: "单据编号", type: "default" },
        { prop: "number", label: "商品合计", type: "number" },
        { prop: "billCount", label: "票据合计", type: "number" },
        { prop: "supplierName", label: "供应商", type: "default" },
        { prop: "legalPerson", label: "联系人", type: "default" },
        { prop: "legalPersonPhone", label: "联系电话", type: "default" },
      ],
      Visible: false,
      formVisible: false,
    };
  },
  activated() {
    // this.GetCustomerType();
    this.getData();
  },
  methods: {
    WatchData(data) {
      this.formVisible = true;
      this.$nextTick(() => {
        this.$refs.editForm.init(data.id, true);
      });
    },
    // 获取客户类别
    GetCustomerType() {
      GetCustomerTypeList().then((res) => {
        if (res.code === 1) {
          const { records } = res.data;
          this.typeList = records;
          // 默认选中
          this.getData();
        } else {
          this.typeList = [];
          this.$message.error("未获取到客户类别");
        }
      });
    },
    backList() {
      this.formVisible = false;
    },
    getData() {
      const params = { ...this.searcForm };
      if (params.date) {
        params.startDate = params.date[0];
        params.endDate = params.date[1];
      }
      console.log(params);
      getPurchaseList(params).then((res) => {
        if (res.code === 1) {
          const { records, current, size, total } = res.data;
          this.tableData = records;
          this.total = total;
          this.searcForm.pageNum = current;
          // 当没有数据
          if (records.length === 0) {
            // 判断是否为第一页
            if (current === 1) {
              this.searcForm.pageNum = 1;
              return;
            } else {
              // 不是回退再重新搜索
              this.searcForm.pageNum = current - 1;
              this.getData();
            }
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 新增
    UpDate() {
      // this.$router.push({ path: "/data/customerForm" });
      this.AddVisible = true;
    },
    // 修改
    EditData(data) {
      this.formVisible = true;
      this.$nextTick(() => {
        this.$refs.editForm.init(data.id, false);
      });
      // this.$router.push({ path: "/data/EditCustomer", query: { id: data.id } });
    },
    refresh() {
      this.getData();
    },
    DeleteData(data) {
      this.$confirm(`是否删除该客户？`, "提示", {
        confirmButtonText: "是",
        cancelButtonText: "否",
        type: "warning",
      }).then(() => {
        DeleteCustomer(data.id).then((res) => {
          const { code, msg } = res;
          if (code === 1) {
            this.$message.success(msg);
          } else {
            this.$message.error(msg);
          }
          this.getData();
        });
      });
    }, // 分页
    changePageSize(val) {
      this.searcForm.pageSize = val;
      this.searcForm.pageNum = 1;
      this.getData();
    },
    changeCurrentPage(val) {
      this.searcForm.pageNum = val;
      this.getData();
    },
    // 客户期初设置
    setting(data) {
      this.submitForm.customerId = data.id;
      this.submitForm.initialPayment = undefined;
      this.submitForm.initialAdvancePayment = undefined;
      this.Visible = true;
      this.$nextTick(() => {
        this.$refs.AddOrEditForm.init("客户期初设置");
      });
    },
    // 客户期初设置提交
    submitData() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.submitForm.initialPayment = +this.submitForm.initialPayment;
          this.submitForm.initialAdvancePayment =
            +this.submitForm.initialAdvancePayment;
          SettingCustomerInitial(this.submitForm).then((res) => {
            const { code, msg } = res;
            if (code === 1) {
              this.$message.success(msg);
              this.close();
              this.getData();
            } else {
              this.$message.error(msg);
            }
          });
        } else {
          return false;
        }
      });
    },
    close() {
      this.$refs.AddOrEditForm.close();
    },
    exportData(id) {
      // 导出
      purchaseLedgerExport(id).then((data) => {
        const fileName = data.fileName || "农药采购（进）货查验记录.docx";
        //   res.data:请求到的二进制数据
        const blob = new Blob([data.data], {
          type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        }); //1.创建一个blob
        const link = document.createElement("a"); //2.创建一个a链接
        link.download = fileName; //3.设置名称
        link.style.display = "none"; // 4.默认不显示
        link.href = URL.createObjectURL(blob); // 5.设置a链接href
        document.body.appendChild(link); //6.将a链接dom插入当前html中
        link.click(); //7.点击事件
        URL.revokeObjectURL(link.href); //8.释放url对象
        document.body.removeChild(link); //9.移除a链接dom
      });
    },
  },
};
</script>
