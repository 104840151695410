<template>
  <div class="container">
    <mia-navback @back="back" :isShowSave="!isView" @submit="submitForm(1)" />
    <mia-Card title="基本信息">
      <div slot="content">
        <el-row class="head">
          <el-col :span="4">
            <el-col :span="7" class="label">单据编号：</el-col>
            <el-col :span="11">{{ purchaseLedger.purchaseNo }}</el-col>
          </el-col>
          <el-col :span="4">
            <el-col :span="7" class="label">台账编号：</el-col>
            <el-col :span="11">{{ purchaseLedger.no }}</el-col>
          </el-col>
          <el-col :span="4">
            <el-col :span="7" class="label">业务日期：</el-col>
            <el-col :span="11">{{ purchaseLedger.entryDate }}</el-col>
          </el-col>
          <el-col :span="5">
            <el-col :span="8" class="label">最后修改时间：</el-col>
            <el-col :span="16">{{ purchaseLedger.updateDate }}</el-col>
          </el-col>
          <el-col :span="4">
            <el-col :span="8" class="label">最后修改人：</el-col>
            <el-col :span="9">{{ purchaseLedger.updateUserName }}</el-col>
          </el-col>
        </el-row>
        <el-table
          :data="purchaseLedger.list"
          border
          style="width: 100%"
          show-summary
          :summary-method="getSummaries"
        >
          <el-table-column type="index" label="序号" width="50">
          </el-table-column>
          <el-table-column prop="productName" label="商品名称">
          </el-table-column>
          <el-table-column prop="productRegCode" label="登记证号" width="180">
          </el-table-column>
          <el-table-column prop="name" label="商标名称">
            <template slot-scope="scope">
              <el-input v-if="!isView" v-model="scope.row.name" size="mini" />
              <span v-else>{{ scope.row.name }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="batchNo" label="生产批号">
            <template slot-scope="scope">
              <el-input
                v-if="!isView"
                v-model="scope.row.batchNo"
                size="mini"
              />
              <span v-else>{{ scope.row.batchNo }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="productionDate" label="生产日期">
            <template slot-scope="scope">
              <el-date-picker
                v-if="!isView"
                v-model="scope.row.productionDate"
                type="date"
                size="mini"
                style="width: 100%"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
              >
              </el-date-picker>
              <span v-else>{{ scope.row.productionDate }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="number" label="数量"> </el-table-column>
          <el-table-column prop="infoCode" label="可追溯电子信息码">
            <template slot-scope="scope">
              <el-select
                v-if="!isView"
                v-model="scope.row.infoCode"
                placeholder="请选择"
                size="mini"
              >
                <el-option
                  v-for="(item, index) in infoCodes"
                  :key="index"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select>
              <span v-else>{{ scope.row.infoCode }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="nongyaoInfoCheck"
            label="中国农药信息网数据中心查询情况"
          >
            <template slot-scope="scope">
              <el-select
                v-if="!isView"
                v-model="scope.row.nongyaoInfoCheck"
                placeholder="请选择"
                size="mini"
              >
                <el-option
                  v-for="(item, index) in nongyaoInfoChecks"
                  :key="index"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select>
              <span v-else>{{ scope.row.nongyaoInfoCheck }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="qualityInspection"
            label="产品质量检验合格证或检验报告"
          >
            <template slot-scope="scope">
              <el-select
                v-if="!isView"
                v-model="scope.row.qualityInspection"
                placeholder="请选择"
                size="mini"
              >
                <el-option
                  v-for="(item, index) in qualityInspections"
                  :key="index"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select>
              <span v-else>{{ scope.row.qualityInspection }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="checkResult" label="检验结果">
            <template slot-scope="scope">
              <el-select
                v-if="!isView"
                v-model="scope.row.checkResult"
                placeholder="请选择"
                size="mini"
              >
                <el-option
                  v-for="(item, index) in checkResults"
                  :key="index"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select>
              <span v-else>{{ scope.row.checkResult }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </mia-Card>
    <mia-Card title="供应商信息">
      <div slot="content">
        <el-form ref="basicForm" :model="supplierForm" label-width="170px">
          <el-row>
            <el-col :span="7">
              <el-form-item
                label="供应商名称"
                prop="supplierName"
                key="barCode"
              >
                <el-input
                  v-model="supplierForm.supplierName"
                  disabled
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item
                label="供应商地址"
                prop="supplierAddress"
                key="supplierAddress"
              >
                <el-input
                  v-model="supplierForm.supplierAddress"
                  :disabled="isView"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="9">
              <el-form-item
                label="农药生产/经营许可证号"
                prop="license"
                key="license"
              >
                <el-input
                  v-model="supplierForm.license"
                  :disabled="isView"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="7">
              <el-form-item
                label="法人/负责人"
                prop="legalPerson"
                key="legalPerson"
              >
                <el-input
                  v-model="supplierForm.legalPerson"
                  :disabled="isView"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item
                label="法人/负责人电话"
                prop="legalPersonPhone"
                key="legalPersonPhone"
              >
                <el-input
                  v-model="supplierForm.legalPersonPhone"
                  :disabled="isView"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="7">
              <el-form-item label="送货人" prop="shipper" key="shipper">
                <el-input
                  v-model="supplierForm.shipper"
                  :disabled="isView"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item
                label="送货人电话"
                prop="shipperPhone"
                key="shipperPhone"
              >
                <el-input
                  v-model="supplierForm.shipperPhone"
                  :disabled="isView"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div style="margin-left: 76px">
          <span style="color: red">*</span>
          <span>经查验，与本地预留信息一致</span>
        </div>
      </div>
    </mia-Card>
    <mia-Card title="票据信息">
      <div slot="content">
        <UploadImg
          style="margin-right: 30px"
          :limit-count="4"
          :disabled="isView"
          :image-list="imageList"
          @imgUploadSuc="imgUploadSuc"
          @imageRemove="imageRemove"
        />
      </div>
    </mia-Card>
  </div>
</template>
<script>
import UploadImg from "@/components/uploadImg";
import {
  getPurchaseLedgerDetails,
  editPuchaseLedger,
} from "@/api/purchaseLedger";
import settting from "../../../../settting";
export default {
  components: { UploadImg },
  props: {},
  data: () => {
    return {
      purchaseLedger: {},
      imageList: [], // 照片反显
      ledgerId: "",
      supplierForm: {
        supplierName: "",
        supplierAddress: "",
        license: "",
        shipper: "",
        shipperPhone: "",
        legalPerson: "",
        legalPersonPhone: "",
      },
      rules: [],
      infoCodes: [
        //电子码信息
        {
          id: "有，信息一致",
          name: "有，信息一致",
        },
        {
          id: "有，信息补全",
          name: "有，信息补全",
        },
        {
          id: "无",
          name: "无",
        },
      ],
      nongyaoInfoChecks: [
        //农药信息核验
        {
          id: "产品标签与查询数据一致",
          name: "产品标签与查询数据一致",
        },
        {
          id: "不一致",
          name: "不一致",
        },
      ],
      qualityInspections: [
        {
          id: "有，核对相符",
          name: "有，核对相符",
        },
        {
          id: "有，核对不符",
          name: "有，核对不符",
        },
        {
          id: "无",
          name: "无",
        },
      ],
      checkResults: [
        {
          id: "查验无问题，进货并录入本店电脑管理系统",
          name: "查验无问题，进货并录入本店电脑管理系统",
        },
        {
          id: "查验有问题，拒收",
          name: "查验有问题，拒收",
        },
      ],
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      isView: false,
    };
  },
  mounted() {},
  methods: {
    init(id, isView) {
      this.ledgerId = id;
      this.isView = isView;
      this.getPurchaseLedgerDetails(id);
    },
    getPurchaseLedgerDetails(id) {
      getPurchaseLedgerDetails(id).then((res) => {
        if (res.code === 1) {
          this.purchaseLedger = res.data;
          this.supplierForm = {
            supplierName: res.data.supplierName,
            supplierAddress: res.data.supplierAddress,
            license: res.data.license,
            shipper: res.data.shipper,
            shipperPhone: res.data.shipperPhone,
            legalPerson: res.data.legalPerson,
            legalPersonPhone: res.data.legalPersonPhone,
          };
          if (res.data.billImage) {
            this.imageList = res.data.billImage.split(",").map((v) => {
              return {
                src: settting.imgUrl + v,
                value: v,
              };
            });
          }
        }
      });
    },
    imgUploadSuc(file, list) {
      const temps = [];
      list.forEach((v) => {
        const picUrl = v.response ? v.response.url : v.value;
        temps.push({
          src: settting.imgUrl + picUrl,
          value: picUrl,
        });
      });
      this.imageList = temps;
    },
    imageRemove(field) {},
    back() {
      this.$emit("back");
    },
    handleRemove(file) {
      console.log(file);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
      console.log(file);
    },
    submitForm() {
      const result = {
        id: this.ledgerId,
        ...this.purchaseLedger,
        ...this.supplierForm,
      };
      result.list = this.purchaseLedger.list;
      result.billImage = this.imageList.map((v) => v.value).join(",");
      editPuchaseLedger(result).then((res) => {
        if (res.code === 1) {
          this.$message.success("提交成功");
          this.$emit("back");
        }
      });
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        if (column.property == "number") {
          const values = data.map((item) => Number(item[column.property]));
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += "";
          } else {
            sums[index] = "";
          }
        }
      });

      return sums;
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  .head {
    height: 100px;
    line-height: 100px;
    margin-left: 20px;
    color: #666;
    .label {
      color: #333;
    }
  }
}
</style>
